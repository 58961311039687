// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agradecimientos-js": () => import("./../../../src/pages/agradecimientos.js" /* webpackChunkName: "component---src-pages-agradecimientos-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-detalle-js": () => import("./../../../src/pages/detalle.js" /* webpackChunkName: "component---src-pages-detalle-js" */),
  "component---src-pages-experiencias-js": () => import("./../../../src/pages/experiencias.js" /* webpackChunkName: "component---src-pages-experiencias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posada-js": () => import("./../../../src/pages/posada.js" /* webpackChunkName: "component---src-pages-posada-js" */),
  "component---src-pages-servicios-alojamiento-js": () => import("./../../../src/pages/servicios/alojamiento.js" /* webpackChunkName: "component---src-pages-servicios-alojamiento-js" */),
  "component---src-pages-servicios-panaderia-js": () => import("./../../../src/pages/servicios/panaderia.js" /* webpackChunkName: "component---src-pages-servicios-panaderia-js" */),
  "component---src-pages-servicios-restaurante-js": () => import("./../../../src/pages/servicios/restaurante.js" /* webpackChunkName: "component---src-pages-servicios-restaurante-js" */)
}

